<template>
  <v-app>
    <v-container v-if="isVerified" class="py-0" style="width: 1200px">
      <v-row>
        <v-col md="12">
          <div class="header-details">
            <table width="100%">
              <tr>
                <td>
                  <img
                    style="width: 200px; height: auto"
                    src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/SL012021VF/files/image/png/MwRhbCQB4IY4bY5pEnlcZiY6mjRT6BEtUz5ss4HM.png"
                    alt="logo"
                  />
                  <div class="pt-3">
                    <div class="text-h5 fw-600">
                      #{{ quotation_detail?.barcode }}
                      <v-chip
                        v-if="quotation_detail?.is_signed"
                        small
                        label
                        class="text-white"
                        color="red"
                      >
                        Signed</v-chip
                      >
                    </div>
                    <div class="text-sm grey--text text-h6 f">
                      {{ quotation_detail?.job_title }}
                    </div>
                  </div>
                </td>
                <td align="right" valign="bottom">
                  <div class="">
                    <v-btn
                      outlined
                      color="blue"
                      depressed
                      class="rounded bg-white"
                      @click="downloadPDFNew"
                      ><v-icon>mdi-pdf</v-icon>Download</v-btn
                    >

                    <v-btn
                      v-if="!quotation_detail?.is_signed"
                      v-on:click="
                        action_type = 'reject';
                        action_dialog = true;
                      "
                      color="red white--text lighten-1"
                      depressed
                      class="mx-2 rounded bg-white"
                      ><v-icon>mdi-x</v-icon>Reject</v-btn
                    >
                    <v-btn
                      v-if="!quotation_detail?.is_signed"
                      depressed
                      color="success"
                      v-on:click="
                        action_type = 'accept';
                        initSignature();
                        accepted_dialog = true;
                      "
                      class="rounded"
                      ><v-icon>mdi-right-check</v-icon>Accept</v-btn
                    >
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </v-col>
        <v-col md="8">
          <div class="bg-white h-100 border pa-3 rounded">
            <v-col md="12" class="py-0 mt-2">
              <table
                width="100%"
                style="
                  border-collapse: collapse;
                  border-spacing: 0 0em;
                  margin-top: 10px;
                "
                cellpadding="2"
              >
                <thead>
                  <tr>
                    <th
                      width="55%"
                      align="left"
                      colspan="4"
                      style="padding: 5px 0; font-size: 13px"
                    >
                      ITEM NAME & DESCRIPTION
                    </th>
                    <th
                      width="9%"
                      style="
                        padding: 5px 0px;
                        font-size: 13px;
                        background: rgb(238, 236, 236);
                      "
                      class="text-center"
                    >
                      QTY
                    </th>
                    <th
                      width="15%"
                      align="right"
                      style="
                        padding-left: 8px;
                        padding-right: 8px;
                        font-size: 13px;
                      "
                      class="text-end"
                    >
                      UNIT PRICE
                    </th>
                    <th
                      width="16%"
                      align="right"
                      style="
                        padding: 5px 8px;
                        font-size: 13px;
                        background: rgb(238, 236, 236);
                      "
                      class="text-end"
                    >
                      TOTAL PRICE
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(lineItem, index) in quotationLineItems"
                    :key="index"
                  >
                    <template v-if="lineItem.product_type === 'header'">
                      <th
                        colspan="4"
                        align="left"
                        valign="top"
                        class="text-capitalize"
                        style="padding-top: 10px"
                      >
                        {{ lineItem.product }}
                      </th>
                      <td
                        style="
                          padding-left: 0px;
                          padding-right: 0px;
                          background: rgb(238, 236, 236);
                        "
                        align="center"
                      >
                        <!-- Qty -->
                      </td>
                      <td
                        style="padding-left: 0px; padding-right: 0px"
                        align="center"
                      >
                        <!-- Unit Price -->
                      </td>
                      <td
                        style="
                          padding-left: 8px;
                          padding-right: 8px;
                          background: rgb(238, 236, 236);
                        "
                        align="right"
                      >
                        <!-- Total Price -->
                      </td>
                    </template>

                    <template
                      v-else-if="
                        lineItem.product_type !== 'header' && !lineItem.is_child
                      "
                    >
                      <td
                        width="4%"
                        align="left"
                        valign="top"
                        style="font-size: 12px"
                      >
                        {{ lineItem.sno }}.
                      </td>
                      <td colspan="3" valign="top" class="text-capitalize">
                        {{ lineItem.product }}
                      </td>
                      <td
                        style="
                          padding-left: 0px;
                          padding-right: 0px;
                          background: rgb(238, 236, 236);
                        "
                        align="center"
                      >
                        {{ lineItem.quantity }}
                      </td>
                      <td
                        style="padding-left: 8px; padding-right: 8px"
                        align="right"
                      >
                        {{ lineItem.selling_cost }}
                      </td>
                      <td
                        style="
                          padding-left: 8px;
                          padding-right: 8px;
                          background: rgb(238, 236, 236);
                        "
                        align="right"
                      >
                        {{ lineItem.total }}
                      </td>
                    </template>

                    <template
                      v-else-if="
                        lineItem.product_type !== 'header' && lineItem.is_child
                      "
                    >
                      <td width="4%" valign="top"></td>
                      <td
                        width="4%"
                        align="left"
                        valign="top"
                        style="font-size: 12px"
                      >
                        <span class="font-bold">{{ lineItem.sno }}</span>
                      </td>
                      <td align="left" colspan="2" class="text-capitalize">
                        {{ lineItem.product }}
                      </td>
                      <td
                        style="
                          padding-left: 0px;
                          padding-right: 0px;
                          background: rgb(238, 236, 236);
                        "
                        align="center"
                      >
                        {{ lineItem.quantity }}
                      </td>
                      <td
                        style="padding-left: 8px; padding-right: 8px"
                        align="right"
                      >
                        {{ formatMoney(lineItem.selling_cost) }}
                      </td>
                      <td
                        style="
                          padding-left: 8px;
                          padding-right: 8px;
                          background: rgb(238, 236, 236);
                        "
                        align="right"
                      >
                        {{ formatMoney(lineItem.total) }}
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>

              <table
                width="100%"
                cellpadding="0"
                style="border-top: 1px solid grey; padding-top: 15px"
              >
                <tr>
                  <td
                    align="right"
                    width="85%"
                    style="padding: 2px 8px; font-size: 14px; line-height: 20px"
                  >
                    Sub Total
                  </td>
                  <td
                    align="right"
                    width="15%"
                    style="padding: 2px 8px; font-size: 14px"
                  >
                    {{ formatMoney(quotation_detail?.sub_total) }}
                  </td>
                </tr>
                <tr>
                  <td
                    align="right"
                    width="85%"
                    style="padding: 2px 8px; font-size: 14px"
                  >
                    GST {{ quotation_detail?.tax_value }} %
                  </td>
                  <td
                    align="right"
                    width="15%"
                    style="padding: 2px 8px; font-size: 14px"
                  >
                    {{ formatMoney(quotation_detail?.tax_amount) }}
                  </td>
                </tr>
                <tr>
                  <th
                    align="right"
                    width="85%"
                    class="text-end"
                    style="padding: 2px 8px; font-size: 14px"
                  >
                    Total Charges
                  </th>
                  <th
                    align="right"
                    class="text-end"
                    width="15%"
                    style="padding: 2px 8px; font-size: 14px"
                  >
                    {{ formatMoney(quotation_detail?.total) }}
                  </th>
                </tr>
              </table>

              <table width="100%">
                <tr>
                  <td>
                    <span
                      class="font-weight-700"
                      style="border-bottom: 1px solid"
                      >Term &amp; Condition</span
                    >
                  </td>
                </tr>
                <tr>
                  <td class="py-1">
                    <div v-html="quotation_detail?.term_conditions"></div>
                  </td>
                </tr>
              </table>

              <table
                v-if="$route.query['security-code'] && false"
                width="100%"
                style="table-layout: fixed"
              >
                <thead class="fw-700">
                  <tr class="signature-pad" id="signature-pad">
                    <td valign="top">
                      <span>Attachment</span><br />
                      <FileTemplate
                        allowUpload
                        isMinDisplay
                        v-on:file:updated="updateAttachments"
                      ></FileTemplate>
                    </td>
                  </tr>
                </thead>
              </table>

              <table width="100%" style="table-layout: fixed">
                <thead class="fw-700">
                  <tr class="signature-pad" id="signature-pad">
                    <td width="33%" valign="top">
                      <span
                        >Yours faithfully <br /><!-- {{
                          company_detail?.company_name
                        }} --></span
                      ><br />
                      <img
                        class="my-2"
                        style="max-width: 280px; max-height: 100px"
                        v-if="prepared && prepared.signature"
                        :src="prepared.signature"
                        alt="prepared by"
                      />
                      <hr />
                    </td>
                    <td width="33%" valign="top"></td>
                    <td width="33%" valign="top">
                      <span>Confirmed and Accepted by:</span>
                      <div
                        v-if="false"
                        ref="customer_signature_div"
                        style="width: 280px"
                        class="custom-border-grey-dashed signature-pad-wrap"
                      >
                        <canvas
                          ref="customer_signature"
                          style="width: 280px; height: 120px !important"
                        ></canvas>
                      </div>
                      <img
                        class="my-2"
                        style="max-width: 280px; max-height: 100px"
                        v-if="
                          quotation_detail &&
                          quotation_detail.accepted_signature
                        "
                        :src="quotation_detail.accepted_signature"
                        alt="Signature by"
                      />
                      <hr />
                    </td>
                  </tr>

                  <tr>
                    <td width="33%">
                      <div>Display Name : {{ prepared?.display_name }}</div>
                      <div>Mobile : {{ prepared?.phone_number }}</div>
                      <div>Email : {{ prepared?.user_email }}</div>
                    </td>
                    <td width="33%">
                      <div></div>
                    </td>
                    <td width="33%">
                      <div>Authorized Signature &amp; Signed Date</div>
                      <div class="text-capitalize">
                        Name : {{ quotation_detail?.signed_first_name }}
                        {{ quotation_detail?.signed_last_name }}
                      </div>
                      <div>Email : {{ quotation_detail?.signed_email }}</div>
                      <div class="text-capitalize">
                        Designation : {{ quotation_detail?.signed_designation }}
                      </div>
                      <div>
                        Date : {{ formatDate(quotation_detail?.accepted_date) }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td height="80px"></td>
                  </tr>
                </thead>
              </table>
              <table width="100%">
                <tfoot>
                  <tr>
                    <td
                      height="30px"
                      colspan="10"
                      align="center"
                      valign="center"
                      class="custom-border-top"
                      style="font-size: 10px"
                    >
                      {{ company_detail.street_1 }},
                      {{ company_detail.street_2 }},
                      {{ company_detail.landmark }}
                      {{ company_detail.zip_code }} Tel:
                      {{ company_detail.phone_number }} •
                      {{ company_detail.website_url }} •
                      {{ company_detail.email_address }}
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div style="position: relative" class="bg-white" v-if="false">
                <div
                  style="position: absolute; width: 100%; bottom: 50px"
                  class="text-right"
                >
                  <v-btn
                    v-on:click="
                      action_type = 'accept';
                      initSignature();
                      accepted_dialog = true;
                    "
                    color="cyan white--text"
                    class="custom-bold-button"
                    :disabled="valid_signature"
                    >Accept</v-btn
                  >
                  <v-btn
                    v-on:click="
                      action_type = 'reject';
                      action_dialog = true;
                    "
                    color="red white--text lighten-1"
                    class="ml-4 custom-bold-button"
                    >Reject</v-btn
                  >
                </div>
              </div>
            </v-col>
          </div>
        </v-col>
        <v-col md="4">
          <div class="tabs-summary">
            <v-tabs
              active-class="custom-tab-active"
              v-model="ticketTab"
              background-color="transparent"
              color="cyan"
              show-arrows
            >
              <v-tab class="font-size-16 font-weight-600" href="#summary">
                Summary
              </v-tab>
              <v-tab class="font-size-16 font-weight-600" href="#discussion">
                <!-- <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  
                  <inline-svg
                    :src="$assetURL('media/custom-svg/line-item.svg')"
                  />
                </span> -->
                Discussion
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="ticketTab"
              style="background-color: none !important"
            >
              <v-tab-item value="summary">
                <div class="summary-data pa-2">
                  <div class="text-h6">{{ company_detail?.company_name }}</div>
                  <div class="text-sm">
                    {{ company_detail?.street_1 }},<br />
                    {{ company_detail?.street_2 }},<br />
                    Singapore {{ company_detail?.zip_code }}
                  </div>

                  <table width="100%" class="mt-3 mb-3">
                    <tr>
                      <td style="font-size: 14px" class="py-0 px-0" width="50%">
                        Sub Total
                      </td>
                      <td class="py-0 px-0">
                        {{ formatMoney(quotation_detail?.sub_total) }}
                      </td>
                    </tr>
                    <tr>
                      <td style="font-size: 14px" class="py-0 px-0">
                        GST {{ quotation_detail?.tax_value }} %
                      </td>
                      <td class="py-0 px-0">
                        {{ formatMoney(quotation_detail?.tax_amount) }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="font-size: 14px"
                        class="py-0 px-0 font-weight-700"
                      >
                        Total Charges
                      </td>
                      <td class="py-0 px-0 font-weight-700">
                        {{ formatMoney(quotation_detail?.total) }}
                      </td>
                    </tr>
                  </table>
                  <table width="100%">
                    <tr>
                      <td class="py-0 px-0" width="50%">Status</td>
                      <td class="py-0 px-0">
                        <v-chip
                          small
                          label
                          class="text-white"
                          :color="getStatusColor(quotation_detail.status)"
                        >
                          {{ getStatustext(quotation_detail.status) }}</v-chip
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="py-0 px-0">Date</td>
                      <td class="py-0 px-0">
                        {{ formatDate(quotation_detail.date) }}
                      </td>
                    </tr>
                    <tr
                      v-if="quotation_detail && quotation_detail.validity_till"
                    >
                      <td class="py-0 px-0">Validity</td>
                      <td class="py-0 px-0 text-capitalize">
                        {{ getValidityDate(quotation_detail.validity_till) }}
                        <span class="red--text">
                          ({{ quotation_detail.validity_till }}Days)
                        </span>
                      </td>
                    </tr>
                  </table>
                  <div class="pt-5 pb-2 text-h6 fw-600">Signature</div>
                  <table width="100%">
                    <tr>
                      <td class="py-0 px-0" width="50%">Signer Name</td>
                      <td class="py-0 px-0 text-capitalize">
                        {{ quotation_detail?.signed_first_name }}
                        {{ quotation_detail?.signed_last_name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="py-0 px-0">Email</td>
                      <td class="py-0 px-0">
                        {{ quotation_detail?.signed_email }}
                      </td>
                    </tr>
                    <tr>
                      <td class="py-0 px-0">Designation</td>
                      <td class="py-0 px-0 text-capitalize">
                        {{ quotation_detail?.signed_designation }}
                      </td>
                    </tr>
                    <tr>
                      <td class="py-0 px-0">Signer Date</td>
                      <td class="py-0 px-0">
                        {{ formatDate(quotation_detail?.accepted_date) }}
                      </td>
                    </tr>
                  </table>
                </div>
              </v-tab-item>
              <v-tab-item value="discussion">
                <div class="discussion-detail pa-2">
                  <div class="comments py-3">
                    <TextAreaField
                      auto-grow
                      dense
                      filled
                      color="cyan"
                      v-model="comments"
                      placeholder="Write Comments.."
                      solo
                      flat
                      row-height="25"
                      counter="250"
                    />
                    <v-btn
                      depressed
                      class="rounded white--text"
                      color="blue"
                      :disabled="notesLoading"
                      :loading="notesLoading"
                      v-on:click="onSubmit"
                      >Add Comment</v-btn
                    >
                  </div>

                  <div class="comments-list">
                    <v-card class="bg-transparent" elevation="0">
                      <template v-for="(msgGrp, index) in msgList">
                        <v-list-item
                          style="
                            border-bottom: 1px solid;
                            border-color: rgba(0, 0, 0, 0.12);
                          "
                          :key="index"
                        >
                          <v-list-item-avatar>
                            <v-img
                              :src="
                                msgGrp?.user_image?.url
                                  ? msgGrp?.user_image?.url
                                  : 'http://127.0.0.1:8080/media/default/no-profile-image.png'
                              "
                            ></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="text-capitalize"
                              ><b>{{ msgGrp.user_name }}</b> -
                              <span>{{
                                msgGrp?.created_at
                              }}</span></v-list-item-title
                            >

                            <div class="sub-title-chat">
                              {{ msgGrp.remark }}
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                        <!-- <v-divider class="my-0" :key="index"></v-divider> -->
                      </template>
                    </v-card>
                  </div>
                  <!--   <div class="pt-5 pb-2 text-h6 fw-600">Signature</div>
                  <table>
                    <tr>
                      <td width="100" class="py-0 px-0">Signer Name</td>
                      <td class="py-0 px-0">John doe</td>
                    </tr>
                    <tr>
                      <td class="py-0 px-0">Signer Date</td>
                      <td class="py-0 px-0">2024-09-30</td>
                    </tr>
                    <tr>
                      <td class="py-0 px-0">IP Address</td>
                      <td class="py-0 px-0">103.185.92.124</td>
                    </tr>
                  </table> -->
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <Dialog :common-dialog="dialog">
      <template v-slot:title> Verification </template>
      <template v-slot:body>
        <v-form
          ref="verificationForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="verifyCustomer"
        >
          <v-row>
            <v-col md="12">
              <v-text-field
                v-model.trim="verificationCode"
                dense
                filled
                label="Security Code"
                solo
                flat
                :disabled="formLoading || pageLoading"
                :loading="formLoading"
                color="cyan"
                :rules="[
                  validateRules.required(verificationCode, 'Security Code'),
                  validateRules.minLength(verificationCode, 'Security Code', 4),
                  validateRules.maxLength(
                    verificationCode,
                    'Security Code',
                    10
                  ),
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="!formValid || formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="verifyCustomer"
        >
          Submit
        </v-btn>
      </template>
    </Dialog>
    <Dialog :common-dialog="action_dialog">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-container>
          <p class="font-size-19 font-weight-600 mx-2 my-0">
            Are you sure, you want to
            <span
              class="font-weight-700"
              :class="{
                'red--text text--lighten-1': action_type == 'reject',
                'green--text text--lighten-1': action_type == 'accept',
              }"
              >{{ action_type }}</span
            >
            this quotation?
          </p>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="action_submit()"
          :disabled="formLoading"
          :loading="formLoading"
        >
          Submit
        </v-btn>
        <v-btn
          depressed
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="action_dialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <Dialog :common-dialog="accepted_dialog">
      <template v-slot:title> Signature & Confirmation Of Identity </template>
      <template v-slot:body>
        <v-container>
          <v-row>
            <v-col md="6">
              <label for="first-name" class="required font-weight-500"
                >First Name</label
              >
              <TextField
                id="first-name"
                dense
                filled
                placeholder="First Name"
                solo
                flat
                counter="50"
                v-model="actionDetail.first_name"
                color="cyan"
                :maxlength="250"
                :rules="[
                  validateRules.required(actionDetail.first_name, 'First Name'),
                ]"
              />
            </v-col>
            <v-col md="6">
              <label for="first-name" class="font-weight-500">Last Name</label>
              <TextField
                id="Last-name"
                dense
                filled
                placeholder="Last Name"
                solo
                flat
                counter="50"
                v-model="actionDetail.last_name"
                color="cyan"
              />
            </v-col>
            <v-col md="6">
              <label for="email" class="required font-weight-500">Email</label>
              <TextField
                id="email"
                dense
                filled
                placeholder="Email"
                solo
                flat
                counter="50"
                v-model="actionDetail.email"
                color="cyan"
                :maxlength="250"
                :rules="[
                  validateRules.validEmail(actionDetail.email, 'email'),
                  validateRules.required(actionDetail.email, 'First Name'),
                ]"
              />
            </v-col>
            <v-col md="6">
              <label for="designation" class="font-weight-500"
                >Designation</label
              >
              <TextField
                id="designation"
                dense
                filled
                placeholder="Designation"
                solo
                flat
                counter="50"
                v-model="actionDetail.designation"
                color="cyan"
                :maxlength="250"
              />
            </v-col>
            <v-col md="12" v-if="false">
              <label for="signature" class="font-weight-500">Attachments</label>
              <file-upload v-model="actionDetail.attachments" :maxLimit="5">
              </file-upload>
            </v-col>
            <v-col md="6" v-if="false">
              <label for="signature" class="font-weight-500 required"
                >Remark</label
              >
              <TextAreaField
                :rules="[validateRules.required(actionDetail.remark, 'Remark')]"
                auto-grow
                dense
                filled
                color="cyan"
                v-model="actionDetail.remark"
                placeholder="Remark"
                solo
                flat
                row-height="25"
                counter="200"
              />
            </v-col>
            <v-col md="12">
              <label
                for="signature"
                class="font-weight-500"
                style="font-size: 18px"
                >Signature</label
              >
              <div
                ref="customer_signature_div"
                class="custom-border-grey-dashed signature-pad-wrap"
              >
                <canvas ref="customer_signature"></canvas>
              </div>

              <div style="position: relative">
                <v-btn
                  style="position: absolute; bottom: 0px; right: -14px"
                  content="Click here to clear signature"
                  v-tippy="{ arrow: true, placement: 'top' }"
                  icon
                  small
                  v-on:click="customer_signature.clear()"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col md="12">
              <v-divider></v-divider>
              <p style="font-size: 14px; color: #security_code">
                By clicking on "Sign", I consent to be legally bound by this
                electronic representation of my signature.
              </p>
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="action_submit()"
          :disabled="formLoading"
          :loading="formLoading"
        >
          Submit
        </v-btn>
        <v-btn
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="accepted_dialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <template v-if="validationErrorBag.length">
      <v-snackbar
        :timeout="5000"
        :value="true"
        top
        color="error"
        right
        v-for="(error, index) in validationErrorBag"
        :key="index"
      >
        {{ error }}
      </v-snackbar>
    </template>
  </v-app>
</template>
<style type="text/css" scoped>
.quotation {
  font-weight: 600;
  text-decoration: underline;
}
.customer-font-bold {
  font-weight: 600;
}
.w-100px {
  width: 100px;
  display: inline-block;
}
.fw-700 {
  font-weight: 700;
}
.mr-5 {
  margin-right: 20px;
}
.fw-600 {
  font-weight: 600;
}
.customer-border-top {
  border-top: solid 2px #000;
}
.customer-border-bottom {
  border-bottom: solid 2px #000;
}
.customer-border-right {
  border-right: solid 2px #000;
}
.customer-border-left {
  border-left: solid 2px #000;
}
.line-items td {
  padding: 4px;
}
table > tbody > tr.signature-pad > td {
  width: 50% !important;
}
.quotation-approved {
  text-align: center;
  padding: 40px 0;
  background: #ebf0f5;
}

.quotation-approved h1 {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.quotation-approved.quotation-rejected h1 {
  color: #f34747;
}
.quotation-approved p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}
.quotation-approved i {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.quotation-approved.quotation-rejected i {
  color: #f34747;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.quotation-approved .card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}
</style>
<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import {
  VERIFY_QUOTATION_CUSTOMER,
  QUERY,
  POST,
} from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin.js";
import SignaturePad from "signature_pad";
import ObjectPath from "object-path";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import FileUpload from "@/view/components/app-component/FileInput";
import JwtService from "@/core/services/jwt.service";
import { ErrorEventBus } from "@/core/lib/message.lib";

import moment from "moment-timezone";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";

export default {
  name: "quotation-approve",
  mixins: [ValidationMixin, CommonMixin],
  data: () => {
    return {
      ticketTab: "summary",
      quotationLineItems: [],
      quotation_line_items: [],
      dialog: true,
      formValid: true,
      currentDate: moment().format("DD-MM-YYYY"),
      formLoading: false,
      isVerified: false,
      isSubmitted: false,
      isRejected: false,
      pageLoading: false,
      action_dialog: false,
      accepted_dialog: false,
      action_type: null,
      tokenNotFound: false,
      verificationCode: null,
      attachments: [],
      securityToken: null,
      today_date: null,
      customer: {},
      billing: {},
      billing_person: {},
      approver: {},
      prepared: {},
      sale_by: {},
      selected_products: [],
      selected_equipments: [],
      customer_signature: null,
      validationErrorBag: new Array(),
      quotation_detail: new Object({
        id: null,
        barcode: null,
        customer: {
          display_name: null,
          company_name: null,
        },
        billing: {
          unit_no: null,
          street_1: null,
          street_2: null,
          zip_code: null,
          country: {
            name: null,
          },
          property_address: null,
        },
        job_title: null,
        sales: null,
        attention: null,
        client_remark: null,
        term_conditions: null,
        additional_remarks: null,
        total: null,
        sub_total: null,
        discount_value: null,
        date: null,
        date_formatted: null,
        property_person: {
          primary_phone: null,
          primary_email: null,
          full_name: null,
        },
        line_items: [
          {
            id: null,
            description: null,
            quantity: null,
            product: {
              name: null,
              unit: {
                id: null,
                text: null,
              },
            },
            sub_total: null,
            discount: null,
          },
        ],
      }),
      company_detail: new Object(),
      actionDetail: {
        first_name: null,
        last_name: null,
        email: null,
        remark: null,
        attachments: [],
        signature: null,
      },
      header_footer: new Object(),
      sales_signature: new Object(),
      comments: null,
      notesLoading: false,
      msgList: [],
    };
  },
  components: {
    Dialog,
    FileTemplate,
    "file-upload": FileUpload,
    TextAreaField,
  },
  methods: {
    downloadPDFNew() {
      const { id } = this.quotation_detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation-signature/${id}/pdf/download?token=${token}&date=${this.download_date}&valid_till=${this.valid_till}`;
      // console.log(url);
      window.open(url, "_blank");
    },
    onSubmit() {
      if (this.comments) {
        this.notesLoading = true;
        this.$store
          .dispatch(POST, {
            url: `quotation-notes/quotation/${this.quotation_detail?.id}`,
            data: {
              notes_remark: this.comments,
              is_signed: 1,
            },
          })
          .then(() => {
            this.comments = "";
            this.notesLoading = false;
            this.getNotesRemark();
          })
          .catch((error) => {
            console.log({ error });
            this.notesLoading = false;
          })
          .finally(() => {
            this.pageLoading = false;
          });
      }
    },
    getNotesRemark() {
      this.$store
        .dispatch(QUERY, {
          url: `quotation-notes-sign/quotation/${this.quotation_detail?.id}`,
          data: {
            is_signed: 1,
          },
        })
        .then(({ data }) => {
          this.msgList = data;
        })
        .catch((error) => {
          console.log({
            error,
          });
        });
    },
    getStatustext(status) {
      switch (status) {
        case 1:
          return "Draft";
        case 2:
          return "Accepted";
        case 3:
          return "Rejected";
        case 4:
          return "Pending";
        case 5:
          return "Sent";
        case 6:
          return "Cancelled";
        case 8:
          return "Approved";
        case 10:
          return "Unapproved";
        case 11:
          return "Submit For Approval";
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 1:
          return "cyan";
        case 2:
          return "green";
        case 3:
          return "red";
        case 4:
          return "cyan";
        case 5:
          return "orange";
        case 6:
          return "cyan";
        case 8:
          return "blue";
        case 10:
          return "#b71c1c";
        case 11:
          return "#ff7300";
      }
    },

    getValidityDate(days) {
      if (this.quotation_detail.added_at) {
        let date = moment(this.quotation_detail.added_at, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        );
        let validity = moment(date, "YYYY-MM-DD")
          .add(days, "days")
          .format("YYYY-MM-DD");
        return this.formatDate(validity);
      }
    },
    fullAddress(address) {
      let addressArray = [];
      if (address.street_1) {
        addressArray.push(address.street_1);
      }
      if (address.street_2) {
        addressArray.push(address.street_2);
      }
      if (address.zip_code) {
        addressArray.push(address.zip_code);
      }
      if (address.country_name) {
        addressArray.push(address.country_name);
      }
      return addressArray.join(", ");
    },
    updateAttachments(param) {
      this.attachments = param;
    },
    action_submit() {
      this.submitSignature(this.action_type);
    },
    getSubTotalAmount(sub_total, discount) {
      return this.formatMoney(this.lodash.sum([sub_total, discount]));
    },
    submitSignature(action) {
      const _this = this;

      if (_this.customer_signature && action == "reject") {
        return false;
      }
      if (!_this.customer_signature && action == "accept") {
        ErrorEventBus.$emit("update:error", "Please submit valid signature.");
        return false;
      }
      _this.formLoading = true;
      _this.isSubmitted = false;
      _this.isRejected = false;

      _this.validationErrorBag = new Array();
      _this.actionDetail.signature = _this.customer_signature
        ? _this.customer_signature.toDataURL()
        : null;
      _this.$store
        .dispatch(VERIFY_QUOTATION_CUSTOMER, {
          url: "quotation/customer/submit",
          data: {
            /*  customer: _this.customer_signature.toDataURL(), */
            code: _this.verificationCode,
            action: action,
            actionDetail: this.actionDetail,
            /* attachments: _this.attachments, */
          },
          token: _this.securityToken,
        })
        .then(() => {
          _this.action_dialog = false;
          _this.accepted_dialog = false;
          _this.verifyCustomer();
          if (action == "reject") {
            _this.isRejected = true;
          } else {
            _this.isSubmitted = true;
          }
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.message) {
            _this.validationErrorBag = response.data.message.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },

    verifyCustomer() {
      const _this = this;

      if (!_this.$refs.verificationForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.isVerified = false;

      _this.validationErrorBag = new Array();

      _this.$store
        .dispatch(VERIFY_QUOTATION_CUSTOMER, {
          url: "quotation/verify",
          data: { code: _this.verificationCode },
          token: _this.securityToken,
        })
        .then(({ data }) => {
          const status = ObjectPath.get(data, "quotation_detail.status", 0);

          if (status == 3) {
            _this.isRejected = true;
          } else if (status == 2) {
            _this.isSubmitted = true;
          }

          _this.quotation_detail = data.quotation_detail;
          _this.getLineItems({ quotation: _this.quotation_detail.id });
          _this.getNotesRemark();
          _this.company_detail = data.company_detail;

          _this.customer = ObjectPath.get(
            data,
            "quotation_detail.customer",
            {}
          );
          _this.billing = ObjectPath.get(data, "quotation_detail.billing", {});
          _this.billing_person = ObjectPath.get(
            data,
            "quotation_detail.billing_person",
            {}
          );
          _this.approver = ObjectPath.get(data, "approver", {});
          _this.prepared = ObjectPath.get(data, "prepared", {});
          _this.sale_by = ObjectPath.get(data, "sale_by", {});

          _this.header_footer = data.header_footer;
          _this.sales_signature = data.sales_signature;
          _this.today_date = data.today_date;
          _this.dialog = false;
          _this.isVerified = true;
          /*  _this.initSignature(); */
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.message) {
            _this.validationErrorBag = response.data.message.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getLineItems(data) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "line-item/new",
          data,
        })
        .then(({ data }) => {
          this.quotationLineItems = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    initSignature() {
      const _this = this;
      _this.$nextTick(() => {
        let ccanvas = _this.$refs["customer_signature"];
        let ccanvasDiv = _this.$refs["customer_signature_div"];
        // let cparentDiv = document.getElementById("signature-pad");
        let cparentWidth = "720"; //cparentDiv.offsetWidth / 2;
        ccanvas.setAttribute("height", 128); // Ensure height consistency
        ccanvas.setAttribute("width", cparentWidth);
        ccanvasDiv.setAttribute("style", "width:" + cparentWidth + "px");
        _this.customer_signature = new SignaturePad(ccanvas);
      });
    },
  },
  mounted() {
    const _this = this;

    if (_this.verificationCode) {
      _this.verifyCustomer();
    }
  },
  created() {
    const _this = this;

    if (_this.$route.query && _this.$route.query.token) {
      _this.securityToken = _this.$route.query.token;
      _this.verificationCode = _this.$route.query["security-code"];
    } else {
      _this.dialog = false;
      _this.tokenNotFound = true;
    }
  },
  computed: {
    valid_signature() {
      return this.customer_signature
        ? this.customer_signature.isEmpty()
        : false;
    },
  },
};
</script>
<style scoped>
.wrapper {
  margin: 0 auto;
  padding: 0px 14px;
}

.wrapper table td {
  font-size: 12px;
}
.v-application td {
  padding: 4px 8px;
}

.tabs-summary .theme--light.v-tabs-items {
  background-color: transparent !important;
}
.signature-pad-wrap {
  height: 128px !important;
  margin-top: 4px;
}
</style>
